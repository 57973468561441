// @ts-ignore __ENV__ is global
const IMG_PATH_PREFIX = __ENV__.IMAGE_CDN_URL.replace("/$", "") // remove trailing slash if applicable

export const IMG_FILE_SIZE_LIMIT = 2 * 1024 * 1024 // 2Mo
export const IMG_DEFAULT_DIMENSIONS_RANGE = {
	min: {
		height: 100,
		width: 100,
	},
	max: {
		height: 1600,
		width: 1600,
	},
}
export const SUPPORTED_IMAGE_FORMATS = {
	gif: "image/gif",
	jpg: "image/jpeg",
	png: "image/png",
}
export const WEBP_MIME_TYPE = "image/webp"
export const IMAGES_MIME_TYPES_HEADERS = {
	[SUPPORTED_IMAGE_FORMATS.jpg]: [0xff, 0xd8, 0xff],
	[SUPPORTED_IMAGE_FORMATS.png]: [0x89, 0x50, 0x4e, 0x47],
	[SUPPORTED_IMAGE_FORMATS.gif]: [0x47, 0x49, 0x46, 0x38],
	[WEBP_MIME_TYPE]: [0x52, 0x49, 0x46, 0x46, 0x57, 0x45, 0x42, 0x50],
}

export type ImageDimensions = {
	width: number
	height: number
}
export type ImageDimensionsRange =
	| { min: ImageDimensions; max?: ImageDimensions }
	| { min?: ImageDimensions; max: ImageDimensions }

export const isImageDimensionsRange = (v: unknown): v is ImageDimensionsRange =>
	typeof v === "object" && v !== null && ("min" in v || "max" in v)

export const getImagePath = (path: string | undefined) => {
	if (typeof path === "string" && path) {
		const url = new URL(path, IMG_PATH_PREFIX)
		return url.toString()
	}
	return ""
}

export const resolveImgPath = (path: string | File | undefined) => {
	if (path instanceof File) return path
	return getImagePath(path)
}

export enum ImageLoaderResult {
	BAD_PARAMETERS = "Could not load image, value is a string or a File",
	INTERNAL_ERROR = "Could not process image",
}
export const imageLoader = (value: unknown) =>
	new Promise((resolve: (img: HTMLImageElement) => void, reject) => {
		if (typeof value === "string" || value instanceof File) {
			const img = new Image()
			img.onload = () => {
				URL.revokeObjectURL(img.src)
				resolve(img)
			}
			img.onerror = () => {
				URL.revokeObjectURL(img.src)
				reject(new Error(ImageLoaderResult.INTERNAL_ERROR))
			}
			img.src = value instanceof File ? URL.createObjectURL(value) : getImagePath(value)
		} else reject(new Error(ImageLoaderResult.BAD_PARAMETERS))
	})
