import { getGlobalEntityLinkAttrs } from "@utils/global-routes"
import type { GlobalEntityPossibleTitleKeys } from "@utils/global-titles"
import clsx from "clsx"
import { type CSSProperties, useEffect, useMemo, useState } from "react"
import type { MinimumIdentifiableEntity } from "types/entities"

import { DEBOUNCE_LONG_DELAY } from "../hooks/use-debounce"
import { CheckIcon } from "../icons/check-icon"
import { ContentCopyIcon } from "../icons/content-copy-icon"
import { IconButton } from "./button"
import { GlobalEntityLink } from "./global-entity-link"

type CopyableValueProps = {
	value: string | number
	escapeSpecialChars?: boolean
	escapeFn?: (v: string | number) => string
}

const useCopyableValue = ({
	value: pValue,
	escapeSpecialChars = false,
	escapeFn = (v: string | number) => `${v}`.replace(/^#\s*/g, ""),
}: CopyableValueProps) => {
	const value = useMemo(
		() => (escapeSpecialChars ? escapeFn(pValue) : `${pValue}`),
		[escapeFn, pValue, escapeSpecialChars],
	)
	return value
}

export const CopyButton = ({
	value: pValue,
	className,
	size = "default",
	label = "Copy to clipboard",
	title: pTitle,
	keepLabel = false,
	copiedLabel = "Copied !",
	variant = "icon",
	escapeSpecialChars = false,
}: {
	className?: string
	size?: "default" | "s" | "ss" | "xs"
	variant?: "icon" | "text-icon"
	label?: string
	keepLabel?: boolean
	copiedLabel?: string
	title?: string
} & CopyableValueProps) => {
	const [mode, setMode] = useState<"initial" | "copied">("initial")
	const [isCopied, setIsCopied] = useState(false)
	const value = useCopyableValue({ value: pValue, escapeSpecialChars })

	useEffect(() => {
		if (isCopied) navigator.clipboard.writeText(value)
		else setIsCopied(false)
	}, [isCopied, value])

	useEffect(() => {
		let timeoutId: number | undefined
		if (isCopied) {
			setMode("copied")
			timeoutId = window.setTimeout(() => {
				setMode("initial")
				setIsCopied(false)
			}, DEBOUNCE_LONG_DELAY)
		}
		return () => clearTimeout(timeoutId)
	}, [isCopied])

	const title = pTitle ?? label

	return (
		<IconButton
			title={isCopied ? copiedLabel : title}
			color={isCopied ? "success" : "grayed"}
			onClick={(e) => {
				e.preventDefault() // prevent default clicks inside links
				setIsCopied(true)
			}}
			className={clsx(className, {
				"animate-button-appear": !className?.includes("opacity-0"),
				"size-5 !p-[2px]": size === "xs",
				"size-6 !p-1": size === "ss",
				"size-7": size === "s",
			})}
		>
			{mode === "copied" ? <CheckIcon /> : <ContentCopyIcon />}
			{variant === "text-icon" ? (label && isCopied && !keepLabel ? copiedLabel : label) : null}
		</IconButton>
	)
}

export function CopyableGlobalEntityTitle({
	entity,
	labelType = "entityLabel",
	className,
}: {
	entity: MinimumIdentifiableEntity
	labelType?: GlobalEntityPossibleTitleKeys
	className?: string
}) {
	const [visible, setVisible] = useState(className?.includes("opacity-0") ?? false)
	const title = getGlobalEntityLinkAttrs(entity)[labelType]

	return (
		<div className={className} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
			<GlobalEntityLink entity={entity} labelType={labelType} />
			<CopyButton
				value={title ?? ""}
				size="ss"
				className={clsx("absolute right-[-0.25rem] top-0", {
					"opacity-0": !visible,
					"opacity-100": visible,
					"animate-button-appear": visible,
				})}
			/>
		</div>
	)
}

export function CopyableLabel({
	label,
	className,
	value,
	loading = false,
	withCopy = true,
	style,
	...rest
}: {
	label: string
	loading?: boolean
	withCopy?: boolean
	className?: string
	style?: CSSProperties
} & CopyableValueProps) {
	const [visible, setVisible] = useState(false)
	return (
		<div
			title={loading ? undefined : `${value}`}
			className={clsx("items-center gap-1 relative", className)}
			style={style}
			onMouseOver={() => setVisible(true)}
			onMouseOut={() => setVisible(false)}
		>
			{withCopy && !loading && (
				<div
					className={clsx("absolute top-0 right-0 bg-white", {
						"opacity-0": !visible,
						"opacity-100": visible,
						"animate-button-appear": visible,
						"z-10": visible,
					})}
				>
					<CopyButton value={value ?? label} size="ss" escapeSpecialChars={rest.escapeSpecialChars} />
				</div>
			)}
			<div>
				{label}
				<div
					className={clsx("truncate absolute top-0 right-0 w-[4rem] h-full", {
						"bg-gradient-to-l from-white from-[2rem] via-[4rem] to-transparent": visible,
						"z-0": visible,
					})}
				/>
			</div>
		</div>
	)
}
