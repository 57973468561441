import { useEffect, useMemo } from "react"
import type { OnChangeValue, OptionProps } from "react-select"
import { components } from "react-select"

import { Badge } from "@components/badge"
import type { SelectProps } from "@components/select"
import { Select } from "@components/select"
import {
	useAllowedCountryOptionsQuery,
	useAllowedViewerCountryOptionsQuery,
	useCountryOptionsQuery,
} from "@graphql/apollo"
import type { CountryOptionFragment } from "@graphql/types"
import { isNotEmpty } from "../../../utils/check"
import { useDefaultCountry } from "../preferences"

export type CountryOption = CountryOptionFragment

function SelectCountryOption<IsMulti extends boolean = false>(props: OptionProps<CountryOption, IsMulti>) {
	const country = props.data

	return (
		<components.Option {...props}>
			<div className="flex items-center gap-2">
				<Badge>{country.alpha2}</Badge>
				{country.name}
			</div>
		</components.Option>
	)
}

export function SelectCountry<IsMulti extends boolean = false>(
	props: SelectProps<CountryOption, IsMulti> & { withDefault?: boolean },
) {
	const { invalid, defaultValue: pDefaultValue, withDefault = true, isMulti, ...rest } = props
	const { data, loading } = useCountryOptionsQuery({
		fetchPolicy: "cache-first",
	})
	const defaultValue = pDefaultValue ?? withDefault ? useDefaultCountry() : undefined
	const newProps = { ...rest }
	const { value: pValue } = newProps
	const hasValue = isNotEmpty(pValue)

	if (!hasValue && defaultValue) newProps.value = undefined
	else newProps.value = pValue

	const options = useMemo(() => {
		if (data?.countries) return data.countries.nodes
		return []
	}, [data])

	// Propagate onChange to react-final-form if no provided value is set
	// and a default values comes from user preferences
	// biome-ignore lint/correctness/useExhaustiveDependencies: precise set of deps
	useEffect(() => {
		if (newProps.onChange && !hasValue && defaultValue) {
			const newVal = isMulti ? ([defaultValue] as CountryOptionFragment[]) : (defaultValue as CountryOptionFragment)
			newProps.onChange(newVal as OnChangeValue<CountryOptionFragment, IsMulti>, {
				action: "select-option",
				option: newVal as CountryOptionFragment | undefined,
			})
		}
	}, [defaultValue])

	return (
		<Select
			{...newProps}
			isMulti={isMulti}
			defaultValue={defaultValue}
			invalid={invalid}
			isLoading={loading}
			options={options}
			getOptionValue={(country) => country.id}
			getOptionLabel={(country) => country.name}
			components={{ Option: SelectCountryOption }}
		/>
	)
}

export function SelectAllowedCountry<IsMulti extends boolean = false>(
	props: SelectProps<CountryOption, IsMulti> & { userId?: string },
) {
	const { invalid, userId, ...rest } = props
	const userResult = useAllowedCountryOptionsQuery({
		fetchPolicy: "cache-first",
		skip: userId == null,
		variables:
			userId == null
				? undefined
				: {
						id: userId,
					},
	})

	const viewerResult = useAllowedViewerCountryOptionsQuery({
		fetchPolicy: "cache-first",
		skip: userId != null,
	})

	const options = useMemo(() => {
		if (userResult.data) {
			return userResult.data.user?.allowedCountries
		}

		if (viewerResult.data) {
			return viewerResult.data.viewer.allowedCountries
		}

		return []
	}, [userResult, viewerResult])

	return (
		<Select
			{...rest}
			invalid={invalid}
			isLoading={userResult.loading || viewerResult.loading}
			options={options}
			getOptionValue={(country) => country.id}
			getOptionLabel={(country) => country.name}
		/>
	)
}
