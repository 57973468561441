import { useIsGranted, useIsGranted_LEGACY } from "@utils/auth"

export enum SecurityMode {
	LEGACY,
	NEW,
}

export function useSecurity() {
	const isGrantedNew = useIsGranted()
	const isGranted = useIsGranted_LEGACY()
	return {
		isGrantedNew,
		isGranted,
	}
}
